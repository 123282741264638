import React, { useState, useEffect, useRef } from 'react';
import { HiMenu } from 'react-icons/hi'; // For the hamburger menu icon
import { IoMdClose } from 'react-icons/io'; // For the close icon
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  let startX = 0;

  // Function to handle navigation to LandingPage and scroll to a specific section
  const handleNavigate = (sectionId) => {
    if (sectionId === 'hero') {
      // If navigating to the HeroSection, reset the scroll to the top
      navigate('/', { state: { scrollTo: 'hero' } });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      // Navigate to other sections
      navigate('/', { state: { scrollTo: sectionId } });
    }
    // Close the mobile menu after navigation
    setIsMenuOpen(false);
  };

  // Simpler method to prevent scrolling
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMenuOpen]);

  // Automatically close the mobile menu when the window is resized to a larger width
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Close menu when clicking outside
  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  // Handle touch swipe to close menu
  const handleTouchStart = (e) => {
    startX = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    const currentX = e.touches[0].clientX;
    const deltaX = currentX - startX;
    // Close if swipe is to the left
    if (deltaX > 50) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
      window.addEventListener('touchstart', handleTouchStart);
      window.addEventListener('touchmove', handleTouchMove);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
    };
  }, [isMenuOpen]);

  return (
    <nav className="z-50 backdrop-blur-3xl md:translate-y-10 md:bg-white/30 md:rounded-2xl md:mx-10 shadow-2xl w-full md:w-auto mx-0 bg-white relative">
      <div className="mx-auto px-2 sm:px-2 lg:px-4">
        <div className="flex justify-between h-12 items-center">
          {/* Logo */}
          <div className="flex-shrink-0">
            <picture>
              <source media="(max-width: 768px)" srcSet="images/nexuzlogomobile.png" />
              <img src="images/nexuz-logo-1.png" alt="Nexuz Logo" className="h-20 md:h-10" />
            </picture>
          </div>
          {/* Hamburger/Close Menu Icon */}
          <div className="flex md:hidden items-center">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className={`text-4xl z-50 ${isMenuOpen ? 'text-white' : 'text-black'}`} // Changes the color of the close icon to white
            >
              {isMenuOpen ? <IoMdClose /> : <HiMenu />}
            </button>
          </div>
          {/* Desktop Navigation Links */}
          <div className="hidden md:flex space-x-8 flex-grow justify-end text-base">
            <button onClick={() => handleNavigate('hero')} className="text-white hover:text-cyan-300 inline-block px-4 py-2 my-auto">
              Home
            </button>
            <button onClick={() => handleNavigate('what-we-do')} className="text-white hover:text-cyan-300 inline-block px-4 py-2 my-auto">
              What We Do?
            </button>
            <button onClick={() => handleNavigate('team')} className="text-white hover:text-cyan-300 inline-block px-4 py-2 my-auto">
              Our Team
            </button>
            <button onClick={() => handleNavigate('projects')} className="text-white hover:text-cyan-300 inline-block px-4 py-2 my-auto">
              Featured Projects
            </button>
            <button onClick={() => handleNavigate('reviews')} className="text-white hover:text-cyan-300 inline-block px-4 py-2 my-auto">
              Client Reviews
            </button>
          </div>
        </div>
      </div>
      {/* Mobile Menu */}
      {isMenuOpen && (
        <div
          ref={menuRef}
          style={{
            backgroundImage: "url('/images/starbg.png')",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundColor: '#111214', // Fallback color
          }}
          className="fixed top-0 right-0 h-[83vh] w-4/5 z-40 p-6 flex flex-col justify-between rounded-l-3xl"
        >
          <div className="flex flex-col space-y-6">
            {/* Navigation Links */}
            <div className="bg-gray-600 h-[0.5vh] w-full mt-[4vh]"></div>
            <button onClick={() => handleNavigate('what-we-do')} className="text-white hover:text-cyan-400 block text-2xl">
              What We Do?
            </button>
            <button onClick={() => handleNavigate('team')} className="text-white hover:text-cyan-400 block text-2xl">
              Our Team
            </button>
            <button onClick={() => handleNavigate('projects')} className="text-white hover:text-cyan-400 block text-2xl">
              Featured Projects
            </button>
            <button onClick={() => handleNavigate('reviews')} className="text-white hover:text-cyan-400 block text-2xl">
              Client Reviews
            </button>
          </div>

          {/* Footer */}
          <div className="text-white text-center text-sm mt-6">
            <p>© Nexuzforge Private Limited 2024.</p>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;

import React from 'react';
import { FaLinkedin } from 'react-icons/fa'; // LinkedIn icon
import { MdEmail } from 'react-icons/md'; // Email icon

const Footer = () => {
  return (
    <div className="overflow-hidden relative z-50 shadow-neon">
      <footer
        className="text-white py-10 shadow-neon rounded-t-full translate-y-1 bg-gray-800/30"
      >
        <div className="flex justify-around mt-2">
          {/* About Us Section */}
          <img
            src="/images/nexuz-logo-1.png"
            className="h-10 mb-5 translate-y-1 ml-2 hidden md:block"
            alt="Nexuz Logo"
          />

          <div className="hidden md:block max-w-xs mr-auto my-auto translate-y-2 ml-10">
            <h4 className="text-lg font-bold">About us</h4>
            <p>
              From web app and UX design to animated video, graphic design,
              marketing campaigns, and business strategy, we craft engaging
              visuals and seamless user experiences to{' '}
              <span className="text-cyan-400">drive growth and success.</span>
            </p>
          </div>

          {/* Contact Us Section */}
          <div className="max-w-xs md:mr-auto my-auto -translate-y-2 mt-4 mx-auto">
            <h4 className="font-bold text-lg mb-4">Contact Us</h4>
            <p className="flex items-center">🇮🇳 +91 96434-03374</p>
            <p className="flex items-center">🇺🇸 +1 (862) 357-5368</p>
            <p className="flex items-center">
              <MdEmail className="mr-2" /> connectwithnexuz@gmail.com
            </p>
          </div>

          {/* Social Media Section */}
          <div className="max-w-xs mr-auto my-auto -translate-y-8">
            <h4 className="font-bold text-lg mb-4 mt-4">Social Media</h4>
            <p className="flex items-center">
              <a
                href="https://in.linkedin.com/company/nexuzdev"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <FaLinkedin className="mr-2" /> LinkedIn
              </a>
            </p>
          </div>
        </div>
        <div className="text-center text-sm md:text-lg mb-6 mt-6">
          © 2024 Nexuzforge Private Limited, India. All rights reserved.
        </div>
      </footer>
      <style jsx global>{`
        :root {
          --neonColor: #37f5eb; /* Cyan neon color */
        }

        .shadow-neon {
          box-shadow: 0px 50px 10px 10px var(--neonColor),
            0px 0px 20px 0px var(--neonColor); /* Neon glow effect */
          border-top-left-radius: 50px;
          border-top-right-radius: 50px;
        }
      `}</style>
    </div>
  );
};

export default Footer;

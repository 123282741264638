import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


const AboutSection = () => {
  const navigate = useNavigate();
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    // Your GSAP animation code
    sectionsRef.current.forEach((section) => {
      gsap.fromTo(
        section,
        { opacity: 0, y: 50 },
        {
          opacity: 1, y: 0, scrollTrigger: {
            trigger: section,
            start: 'top 80%',
            end: 'bottom 60%',
            scrub: true,
            toggleActions: 'play none none reverse'
          }
        }
      );
    });
  }, []);
  const handleNavigate = () => {
    navigate('/projects');  // Navigates to the ProjectSection
  };
  const contentArray = [
    {
      subheading: 'Ux Designs',
      image: '/images/uxdes.png',
    },
    {
      subheading: 'Digital Graphics',
      image: '/images/graphics.png',
    },
    {
      subheading: 'Web Apps',
      image: '/images/webapps.png',
    },
    {
      subheading: 'Animated Videos',
      image: '/images/animatedvid.png',
    },
    {
      subheading: 'Marketing Campaigns',
      image: '/images/marketing.png',
    },
    {
      subheading: 'Business Strategy',
      image: '/images/bizstrat.png',
    },
  ];

  const [currentState, setCurrentState] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentState((prevState) => (prevState + 1) % contentArray.length);
    }, 1000);
    return () => clearInterval(interval);
  }, [contentArray.length]);

  const titleAnimationStyles = {
    animation: 'slide 40s linear infinite',
  };
  const sectionsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('hover-ring');
          } else {
            entry.target.classList.remove('hover-ring');
          }
        });
      },
      { threshold: 0.5 }
    );

    sectionsRef.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      if (sectionsRef.current) {
        sectionsRef.current.forEach((section) => {
          if (section) observer.unobserve(section);
        });
      }
    };
  }, []);

  return (
    <div
      id="what-we-do"
      className="px-0  pt-6 w-screen h-auto relative z-50 md:rounded-t-3xl border-t-[7px] border-t-cyan-400 mb-0 -mt-2    custom-shadow 
"
    >

      {/* Existing content */}
      <div className="overflow-hidden whitespace-nowrap ">
        <h1 className="md:text-8xl text-7xl text-center text-white flex items-center justify-center md:space-x-16 space-x-2 font-['Vidaloka']" style={titleAnimationStyles}>
          We Are Nexuzforge
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">We Are Nexuzforge</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">We Are Nexuzforge</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">We Are Nexuzforge</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">We Are Nexuzforge</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">We Are Nexuzforge</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">We Are Nexuzforge</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">We Are Nexuzforge</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">We Are Nexuzforge</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">We Are Nexuzforge</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">We Are Nexuzforge</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">We Are Nexuzforge</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">We Are Nexuzforge</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">We Are Nexuzforge</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">We Are Nexuzforge</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">We Are Nexuzforge</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">We Are Nexuzforge</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">We Are Nexuzforge</span>
        </h1>
        <div className="w-screen h-[0.1vh] bg-gray-500 mt-8"></div>

      </div>

      {/* Flex container for the content */}
      <div className="flex flex-col md:flex-row md:mt-8 mt-4 text-white mx-auto px-4 md:px-8 w-[100vw]">
        {/* Left side: Headings, Image (on small devices), Paragraph, and Button */}
        <div className="w-full md:w-1/2 flex flex-col mx-auto">
          <h2 className="text-6xl md:text-7xl lg:text-8xl font-['Vidaloka'] md:mb-4">
            We Create,
          </h2>
          <h2 className="text-4xl md:text-5xl lg:text-6xl mb-4 md:mb-14 text-cyan-500 font-['Vidaloka']">
            {contentArray[currentState].subheading}
          </h2>
          {/* Image displayed on small devices */}
          <img
            src={contentArray[currentState].image}
            alt={"Image for state ${currentState + 1}"}
            className="w-[40vh] h-auto object-cover rounded-lg md:hidden mb-14 mx-auto"
          />
          <div className="text-sm md:text-xl md:w-[40vw] md:ml-12 md:mb-14 text-center md:text-left">
            <ul className="list-disc list-inside space-y-2">
              <li>
                Companies that invest in user experience can see a return of up to <span className="text-cyan-400">90X</span> on every dollar spent.
              </li>
              <li>
                Businesses prioritizing design outperform others by <span className="text-cyan-400">219%</span> on the S&P 500.
              </li>
              <li>Boost your <span className="text-cyan-400">ROI</span> and drive growth with our tailored digital solutions.</li>
            </ul>
          </div>

          <div className="flex justify-start md:justify-start my-4 mx-auto md:ml-4">
            <button
              onClick={handleNavigate}
              className="bg-white text-black py-2 md:py-3 px-6 rounded-full hover:bg-cyan-400 flex items-center w-auto"
            >
              <div>Start a Project</div>
              <div className="w-[10px] h-[10px] bg-black/60 rounded-full ml-4"></div>
            </button>
          </div>
        </div>

        {/* Right side: Image displayed on medium and larger devices */}
        <div className="w-1/2 mx-auto justify-center items-center md:p-4 hidden md:flex">
          <img
            src={contentArray[currentState].image}
            alt={"Image for state ${currentState + 1}"}
            className="w-full h-auto object-cover rounded-lg md:w-[40vw]"
          />
        </div>
      </div>

      <div className="hidden md:flex justify-center bg-[#f9f7f3] md:py-12 rounded-lg mt-10 mx-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-10 text-center items-center md:px-8">

          {/* Brand Reputation */}
          <div>
            <div className="rounded-full w-40 h-40 mx-auto mb-4">
              <img src="/images/brandrep.png" alt="Enhanced Brand Reputation" className="rounded-full object-cover w-full h-full animate-" />
            </div>
            <h1 className="font-medium text-lg animate w-4/5 mx-auto">Enhanced Brand Reputation</h1>
          </div>

          {/* Data-Driven Iteration */}
          <div>
            <div className="rounded-full w-40 h-40 mx-auto mb-4">
              <img src="/images/datadriven.png" alt="Data-Driven Iteration and Improvement" className="rounded-full object-cover w-full h-full" />
            </div>
            <h1 className="font-medium text-lg ">Data-Driven Iteration and Improvement</h1>
          </div>

          {/* User-Centric Approach */}
          <div>
            <div className="rounded-full w-40 h-40 mx-auto mb-4">
              <img src="/images/usercentric.png" alt="User-Centric Approach" className="rounded-full object-cover w-full h-full" />
            </div>
            <h1 className="font-medium text-lg w-[70%] mx-auto">User-Centric Approach</h1>
          </div>

          {/* Differentiation in Competitive Markets */}
          <div>
            <div className="rounded-full w-40 h-40 mx-auto mb-4">
              <img src="/images/comp.png" alt="Differentiation in Competitive Markets" className="rounded-full object-cover w-full h-full" />
            </div>
            <h1 className="font-medium text-lg">Differentiation in Competitive Markets</h1>
          </div>
        </div>
      </div>
      <div className="text-white py-16">
        <div className="text-center md:mb-10">
          <h1 className="text-4xl mb-4 font-['Vidaloka'] hidden md:block">Unleashing The Nexuz Within</h1>
          <p className="hidden md:block text-lg">Transforming Customer Satisfaction, Engagement, and Business Metrics.</p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 px-4 md:px-16 gap-4 md:gap-8">

          {/* Increasing User Engagement */}
          <div className="bg-[#414141]  p-3 md:py-16 rounded-lg transform transition-all duration-300 shadow-xl ring-2 ring-teal-400 hover-ring hover-ring-teal">
            <h2 className="text-xl md:text-2xl font-semibold text-teal-400 mb-4 text-center">Increasing User Engagement</h2>
            <p className="md:text-xl text-sm text-center">
              User engagement fuels growth. Incorporating gamification and personalized recommendations boosted session durations by 25% and daily active users by 15%. These efforts lead to higher retention and increased customer lifetime value.
            </p>
          </div>

          {/* Impact On Business Metrics */}
          <div className=" bg-[#f0f0f0] text-[#414141] p-3 md:py-16 rounded-lg transform transition-all duration-300 shadow-xl ring-2 ring-gray-500 hover-ring hover-ring-gray">
            <h2 className="md:text-2xl text-xl font-semibold mb-4 text-center">Impact On Business Metrics</h2>
            <p className="md:text-xl text-sm  text-center">
              Investing in product experience pays off. Our focus on improving experience lowered churn by 40%, driving a 25% increase in recurring revenue. Our Net Promoter Score rose by 15 points, reflecting stronger customer advocacy and organic growth.
            </p>
          </div>

          {/* Enhancing Customer Satisfaction */}
          <div className="bg-[#414141] p-3 md:py-16 rounded-lg transform transition-all duration-300 shadow-xl ring-2 ring-teal-400 hover-ring hover-ring-teal">
            <h2 className="md:text-2xl text-xl font-semibold text-teal-400 mb-4 text-center">Enhancing Customer Satisfaction</h2>
            <p className="md:text-xl text-sm text-center">
              Customer satisfaction drives business success. Businesses with higher satisfaction scores see 20% higher revenue growth. At Nexuz, focusing on intuitive design reduced customer support tickets by 30%, ensuring happier customers who return and recommend our products.
            </p>
          </div>

        </div>


      </div>

      {/* Custom CSS for the neon shadow and animation */}
      <style jsx global>{`
          @keyframes slide {
            0% {
              transform: translateX(100%);
            }
            100% {
              transform: translateX(-100%);
            }
          }
            
  
 
  
          .hover-ring::before,
          .hover-ring::after {
            content: '';
            position: absolute;
            border-radius: 10px;
            pointer-events: none;
            transition: all 0.4s ease-out;
            border: 1.5px solid transparent;
          }
  
          .hover-ring::before {
            top: -10px;
            right: -10px;
            bottom: -10px;
            left: -10px;
            border-radius: inherit;
          }
  
          .hover-ring::after {
            top: -15px;
            right: -15px;
            bottom: -15px;
            left: -15px;
            border-radius: inherit;
          }
  
          .hover-ring-teal:hover::before {
            border-color: rgba(45, 212, 191, 1);
          }
  
          .hover-ring-teal:hover::after {
            border-color: rgba(45, 212, 191, 0.5);
          }
  
          .hover-ring-gray:hover::before {
            border-color: rgba(107, 114, 128, 1);
          }
  
          .hover-ring-gray:hover::after {
            border-color: rgba(107, 114, 128, 0.5);
          }
        `}</style>
    </div >
  );
};

export default AboutSection;
import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

const Privacy = () => {
    return (
        <>
            <Navbar />
            <div className="privacy-policy text-white md:mt-24 p-6 md:p-12 ">
                <h1 className="text-4xl md:text-5xl font-bold mb-8 text-white">Privacy Policy.</h1>

                <p className="text-lg mb-6">
                    This Privacy Policy outlines how we handle your personal information collected through form submissions for project inquiries, meeting scheduling, and other communication. We prioritize your privacy and ensure that your data is secure and handled responsibly.
                </p>

                <section className="mb-6">
                    <h2 className="text-2xl font-semibold text-cyan-400 mb-2">1. Information Collection</h2>
                    <p className="text-lg">
                        We collect personal information that you provide directly, such as your name, email, contact number, and any additional information relevant to setting up meetings and initiating projects. This information helps us reach out to you to discuss project requirements and potential collaborations.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-2xl font-semibold text-cyan-400 mb-2">2. Use of Information</h2>
                    <p className="text-lg">We use your information for the following purposes:</p>
                    <ul className="list-disc list-inside text-lg ml-6">
                        <li>Contacting you about your project inquiries, setting up meetings, and discussing your goals.</li>
                        <li>Providing information on our services, if requested.</li>
                        <li>Complying with legal and regulatory obligations as required.</li>
                    </ul>
                </section>

                <section className="mb-6">
                    <h2 className="text-2xl font-semibold text-cyan-400 mb-2">3. Data Retention</h2>
                    <p className="text-lg">
                        We retain your personal information only as long as necessary to fulfill the purposes outlined in this policy or as required by law. When it is no longer needed, we securely delete or anonymize your information.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-2xl font-semibold text-cyan-400 mb-2">4. Data Security</h2>
                    <p className="text-lg">
                        We employ industry-standard security measures to safeguard your personal information, including encryption, secure storage solutions, and routine security audits. However, please note that no data transmission over the internet is 100% secure.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-2xl font-semibold text-cyan-400 mb-2">5. Sharing of Information</h2>
                    <p className="text-lg">
                        We do not sell or trade your personal information to third parties. We may share your data with trusted service providers solely for the purpose of supporting our services, under strict confidentiality agreements. 
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-2xl font-semibold text-cyan-400 mb-2">6. Your Rights</h2>
                    <p className="text-lg">
                        Depending on your location, you may have the right to access, correct, delete, or restrict the processing of your personal information. To exercise these rights, please contact us through our website.
                    </p>
                </section>

                <section className="mb-6">
                    <h2 className="text-2xl font-semibold text-cyan-400 mb-2">7. Changes to This Policy</h2>
                    <p className="text-lg">
                        We may update this policy periodically. Your continued use of our site after any updates indicates your acceptance of the revised terms.
                    </p>
                </section>

                <section>
                    <h2 className="text-2xl font-semibold text-cyan-400 mb-2">8. Contact Us</h2>
                    <p className="text-lg">
                        If you have any questions regarding this policy, please contact us through the contact information provided on our website.
                    </p>
                </section>
            </div>
            <Footer />
        </>
    );
};

export default Privacy;

import React from 'react';
import Navbar from './Navbar'; // Assuming Navbar is imported
import AnimatedBackground from './AnimatedBackground';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const HeroSection = () => {
  const [isTypingDone, setTypingDone] = useState(false);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/projects'); // Navigates to the ProjectSection
  };

  useEffect(() => {
    const typingDuration = 3000; // 3 seconds matches the typing animation duration
    const timer = setTimeout(() => {
      setTypingDone(true);
    }, typingDuration);

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  return (
    <div className="relative h-[100vh] md:h-[90vh] w-screen z-20">
      {/* Background gradient with dark overlay */}
      <AnimatedBackground />

      {/* Navbar should be above the background */}
      <Navbar />

      {/* Hero content */}
      <section className="relative z-10 flex flex-col md:flex-row justify-between items-center h-full md:px-10 pb-[10vh] pt-[5vh] md:py-0">
  <div className="md:w-1/2 w-screen h-[35vh] md:h-auto text-left flex flex-col justify-between items-start">
    <h1 className="text-6xl md:text-7xl lg:text-8xl md:mb-6 text-white font-vidaloka px-2 md:px-0">
      We create
      <span
        className={`text-cyan-400 animate-typing overflow-hidden whitespace-nowrap border-r-4 ${
          isTypingDone ? 'border-transparent' : 'border-white'
        } block w-full md:w-fit pb-3 lg:text-7xl sm:text-5xl text-4xl md:text-6xl`}
      >
        Digital Products.
      </span>
    </h1>
    <p className="md:text-3xl text-lg md:mb-6 text-white my-auto mt-4 md:mt-0 px-4 md:ml-0">
      Crafting immersive digital experiences that captivate and connect.
    </p>

    {/* Button positioned below the paragraph on medium and larger screens */}
    <div className="hidden md:flex">
      <button
        onClick={handleNavigate}
        className="bg-white text-black py-3 px-6 rounded-full hover:bg-cyan-400 flex justify-center items-center mt-4"
      >
        <div>Start a Project</div>
        <div className="w-[10px] h-[10px] bg-black/60 rounded-full ml-4"></div>
      </button>
    </div>
  </div>

  {/* GIF section and button for small screens */}
  <div className="w-full md:w-2/5 mt-3 flex flex-col justify-center items-center md:block">
    {/* GIF container */}
    <div className="relative w-full h-[30vh] md:w-[33vw] lg:w-[35vw] md:h-[60vh] overflow-hidden rounded-2xl mb-auto md:mb-0">
      <img
        className="absolute inset-0 w-full h-full object-cover"
        src="/images/landing.gif"
      />
    </div>

    {/* Button for small screens */}
    <div className="md:hidden">
      <button
        onClick={handleNavigate}
        className="bg-white text-black py-2 px-6 rounded-full hover:bg-cyan-400 flex justify-center items-center mb-6 mt-6"
      >
        <div>Start a Project</div>
        <div className="w-[10px] h-[10px] bg-black/60 rounded-full ml-4"></div>
      </button>
    </div>
  </div>
</section>


    </div>
  );
};

export default HeroSection;

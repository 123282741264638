import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';


const TeamSection = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/projects');  // Navigates to the ProjectSection
  };

  const contentArray = [
    {
      firstName: 'Kushagra',
      lastName: 'Verma',
      email: 'kush@nexuzforge.com',
      img: '/images/kush.png',
      role: 'Product Development \n Chief Technology Officer'
    },
    {
      firstName: 'Utkarsh',
      lastName: 'Sharma',
      email: 'utkarsh@nexuzforge.com',
      img: '/images/utkarsh.png',
      role: 'UI/UX Design \n Business Development'
    },
    {
      firstName: 'Raghav',
      lastName: 'Maheshwari',
      email: 'raghav@nexuzforge.com',
      img: '/images/raghav.png',
      role: 'Graphic Design \n Head of Marketing'
    },
  ];

  const [currentState, setCurrentState] = useState(0);

  const handleNext = () => {
    setCurrentState((prevState) => (prevState + 1) % contentArray.length);
  };
  const titleAnimationStyles = {
    animation: 'slide 40s linear infinite',
  };

  return (
    <div id="team"
      className=" w-screen h-auto relative border-t-[0.1vh] border-t-gray-500"
      style={{
        backgroundImage: 'url("/images/starbg.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
    }}
    >
      {/* Title with animation */}
      <div className="overflow-hidden whitespace-nowrap pt-10">
        <h1 className="md:text-8xl text-7xl text-center text-white flex items-center justify-center md:space-x-16 space-x-2 font-['Vidaloka']" style={titleAnimationStyles}>
          Meet Our Team
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">Meet Our Team</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">Meet Our Team</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">Meet Our Team</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">Meet Our Team</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">Meet Our Team</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">Meet Our Team</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">Meet Our Team</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">Meet Our Team</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">Meet Our Team</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">Meet Our Team</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">Meet Our Team</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">Meet Our Team</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">Meet Our Team</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">Meet Our Team</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">Meet Our Team</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">Meet Our Team</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">Meet Our Team</span>
        </h1>
        <div className="w-screen h-[0.1vh] bg-gray-500 mt-8"></div>
      </div>

      {/* Flex container for the content */}
      <div className="flex flex-row mt-8 text-white bg-[#2d2d2d] rounded-ss-3xl rounded-ee-3xl max-w-5xl md:mx-auto p-4  md:space-y-0 md:space-x-4 md:h-auto md:px-8 mx-3 h-[40vh]">
        {/* Left side: Subheading and paragraph */}
        <div className="flex-1 p-0 md:p-4 flex flex-col">
          <img src="/images/nexuz-logo-1.png" className="mr-auto h-7 md:h-16"></img>
          <div className="flex justify-end flex-col flex-grow">
            <div>
              <h2 className="text-3xl md:text-8xl font-semibold md:mb-4  mt-auto font-['Vidaloka']">{contentArray[currentState].firstName}</h2>
            </div>
            <div className="flex justify-start">
              <h2 className="text-2xl md:text-7xl font-semibold mb-1 text-cyan-500 font-['Vidaloka']">{contentArray[currentState].lastName}</h2>
            </div>
            <div className="flex justify-start">
              <p className="text-xs md:text-2xl mt-auto">{contentArray[currentState].email}</p>
            </div>
          </div>

          {/* Button for Next Member */}
          <div className="flex justify-start mt-4">
            <button
              className="bg-white text-black md:py-3 md:px-6 px-2 py-1  rounded-full hover:bg-cyan-400 flex items-center text-sm md:text-lg"
              onClick={handleNext}
            >
              {/* The button displays "Next" with the next member's name */}
              Next: {contentArray[(currentState + 1) % contentArray.length].firstName}
              <div className="w-[10px] h-[10px] bg-black/60 rounded-full ml-4"></div>
            </button>
          </div>
        </div>

        {/* Right side: Images */}
        <div className="flex-shrink-0 md:w-[33vw] w-[40vw] flex flex-col justify-center items-center p-2 md:p-4">
          <img
            src={contentArray[currentState].img}
            alt={`Image for state ${currentState + 1}`}
            className="w-full h-auto object-cover rounded-lg"
          />
              <p style={{ whiteSpace: 'pre-line' }} className="text-xs md:text-2xl text-center font-['Vidaloka'] text-white/80 ">{contentArray[currentState].role}</p>
              </div>

        {/* Cofounder section */}
        <div className="md:flex flex-col items-center my-auto text-right hidden">
          <span className="text-xs md:text-xl font-bold">C</span>
          <span className="text-xs md:text-xl font-bold">O</span>
          <span className="text-xs md:text-xl font-bold text-gray-500">F</span>
          <span className="text-xs md:text-xl font-bold text-gray-500">O</span>
          <span className="text-xs md:text-xl font-bold text-gray-500">U</span>
          <span className="text-xs md:text-xl font-bold text-gray-500">N</span>
          <span className="text-xs md:text-xl font-bold text-gray-500">D</span>
          <span className="text-xs md:text-xl font-bold text-gray-500">E</span>
          <span className="text-xs md:text-xl font-bold text-gray-500">R</span>
        </div>
      </div>


      <section className="relative z-10 flex flex-col md:flex-row justify-between items-center md:h-screen md:px-8 md:mt-12 mb-8 px-8">
        {/* Left Side Content */}
        <div className="flex flex-col md:w-1/2 md:items-start items-center">
          {/* Heading */}
          <h1 className="text-5xl mt-10 md:mt-0 md:text-8xl md:mb-6 text-white font-['Vidaloka']">
            Ready To Start
            <span className="text-cyan-400 block w-fit pb-3 font-['Vidaloka']">
              The Project?
            </span>
          </h1>

          {/* Paragraph (only visible on medium and larger screens) */}
          <p className="md:text-2xl text-sm mb-6 text-white hidden md:block">
            Empower Your Vision with Nexuz, Where Innovation Meets Excellence. Let's Collaborate to Craft Exceptional Digital Experiences Tailored Just for You.
          </p>

          {/* Button */}
          <div className="mt-4">
            <button
              onClick={handleNavigate}
              className="bg-white text-black py-2 md:py-3 px-6 rounded-full hover:bg-cyan-400 justify-center items-center hidden md:flex"
            >
              <div>Start a Project</div>
              <div className="w-[10px] h-[10px] bg-black/60 rounded-full ml-4"></div>
            </button>
          </div>
        </div>

        {/* Graphic */}
        <div className="mt-0 md:w-1/2 flex justify-center">
          <div className="relative md:w-[45vw] w-[90vw] h-[40vh] md:h-[85vh] overflow-hidden rounded-2xl">
            <img
            src="/images/rocket.gif"
              alt="Animation"
              className="absolute inset-0 w-full h-full object-cover"
            />
          </div>
        </div>
        <div className="mt-4 md:hidden">
            <button
              onClick={handleNavigate}
              className="bg-white text-black py-2 px-6 rounded-full hover:bg-cyan-400 flex justify-center items-center mb-4"
            >
              <div>Start a Project</div>
              <div className="w-[10px] h-[10px] bg-black/60 rounded-full ml-4"></div>
            </button>
          </div>
      </section>



      <style jsx global>{`
        @keyframes slide {
          0% {
            transform: translateX(100%);
          }
          100% {
            transform: translateX(-100%);
          }
        }
      `}</style>
    </div>
  );
};

export default TeamSection;

import React, { useState, useEffect, useRef } from 'react';
import { FiX } from 'react-icons/fi';
import * as THREE from 'three';






const BoxDisplay = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    const scene = new THREE.Scene();
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
    mountRef.current.appendChild(renderer.domElement);

    const updateRendererSize = () => {
      const width = mountRef.current.clientWidth;
      const height = mountRef.current.clientHeight;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    };

    const camera = new THREE.PerspectiveCamera(50, mountRef.current.clientWidth / mountRef.current.clientHeight, 0.1, 1000);
    camera.position.z = 5;
    updateRendererSize();
    window.addEventListener('resize', updateRendererSize);

    const isMobile = window.innerWidth <= 768;
    const boxWidth = isMobile ? 2 : 3;
    const boxHeight = isMobile ? 1.3 : 2;
    const boxDepth = isMobile ? 1.3 : 2;
    camera.position.z = isMobile ? 4 : 5;

    const geometry = new THREE.BoxGeometry(boxWidth, boxHeight, boxDepth);
    const textureLoader = new THREE.TextureLoader();
    const materials = [
      new THREE.MeshStandardMaterial({ map: textureLoader.load('/images/optiright.jpeg'), roughness: 0.3, metalness: 0.1 }),
      new THREE.MeshStandardMaterial({ map: textureLoader.load('/images/optileft.jpeg'), roughness: 0.3, metalness: 0.1 }),
      new THREE.MeshStandardMaterial({ map: textureLoader.load('/images/optitop.jpeg'), roughness: 0.3, metalness: 0.1 }),
      new THREE.MeshStandardMaterial({ map: textureLoader.load('/images/optitop.jpeg'), roughness: 0.3, metalness: 0.1 }),
      new THREE.MeshStandardMaterial({ map: textureLoader.load('/images/optifront.jpeg'), roughness: 0.3, metalness: 0.1 }),
      new THREE.MeshStandardMaterial({ map: textureLoader.load('/images/optifront.jpeg'), roughness: 0.3, metalness: 0.1 })
    ];

    const box = new THREE.Mesh(geometry, materials);
    box.rotation.x = Math.PI / 8; // Tilt the box slightly on X-axis
    box.rotation.y = Math.PI / 8; // Tilt the box slightly on Y-axis
    scene.add(box);

    const ambientLight = new THREE.AmbientLight(0xffffff, 1.5);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 2.5);
    directionalLight.position.set(5, 5, 5);
    scene.add(directionalLight);

    const rotationFactor = isMobile ? 2.5 : 1.5;

    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;
      const { innerWidth, innerHeight } = window;
      const xRotation = ((clientY / innerHeight) - 0.5) * Math.PI * rotationFactor;
      const yRotation = ((clientX / innerWidth) - 0.5) * Math.PI * rotationFactor;
      box.rotation.x = xRotation;
      box.rotation.y = yRotation;
    };

    const handleTouchMove = (event) => {
      const touch = event.touches[0];
      const { clientX, clientY } = touch;
      const { innerWidth, innerHeight } = window;
      const xRotation = ((clientY / innerHeight) - 0.5) * Math.PI * rotationFactor;
      const yRotation = ((clientX / innerWidth) - 0.5) * Math.PI * rotationFactor;
      box.rotation.x = xRotation;
      box.rotation.y = yRotation;
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('touchmove', handleTouchMove);

    const animate = () => {
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    };
    animate();

    return () => {
      window.removeEventListener('resize', updateRendererSize);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('touchmove', handleTouchMove);
      if (mountRef.current) {
        mountRef.current.removeChild(renderer.domElement);
      }
      renderer.dispose();
    };
  }, []);

  return (
    <div className="relative w-full h-[400px] mx-auto flex justify-center items-center">
      <div ref={mountRef} className="w-full h-full" />

    </div>

  );
};







const ProjectCard = ({ title, description, details, logo, cover, tags, show3DModel, onExpand }) => {
  return (
    <div className="bg-[#2d2d2d]/50 p-4 mx-2 rounded-lg transition-all duration-500 ease-in-out transform hover:shadow-lg md:w-auto relative h-full text-center">
      <div className="flex flex-col items-center justify-center">
        <img src={logo} alt={`${title} logo`} className="h-52 md:h-80 w-fit rounded-lg object-cover mb-4" />
        <h3 className="md:text-3xl text-2xl font-bold text-white mb-2">{title}</h3>
        <p className="text-gray-400 text-sm md:text-base">{description}</p>
      </div>

      <div className="flex flex-wrap gap-2 mt-4 justify-center">
        {tags.map((tag, index) => (
          <span key={index} className="bg-gray-700 text-white text-xs font-medium px-3 py-1 md:px-5 md:py-2 rounded-full">
            {tag}
          </span>
        ))}
      </div>

      <button onClick={onExpand} className="mt-4 bg-cyan-500 text-white py-2 px-6 rounded-3xl flex justify-center items-center mx-auto hover:bg-cyan-500/70">
        Experience
        <div className="w-[10px] h-[10px] bg-white rounded-full ml-4"></div>
      </button>
    </div>
  );
};

const openPortfolio = () => {
  window.open('/images/portfolio.pdf', '_blank');
};
const ExpandedProjectCard = ({ title, description, details, show3DModel, onClose, images }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50 backdrop-blur-sm">
      <div className="bg-[#2d2d2d]/60 rounded-lg p-8 md:w-4/5 w-[90vw] max-h-[95vh] overflow-y-auto relative">
        <button onClick={onClose} className="absolute top-4 right-4 text-cyan-500">
          <FiX className="h-8 w-8" />
        </button>
        <div className="flex">
          <h3 className="md:text-5xl text-3xl font-bold text-white mb-4">{title}</h3>

        </div>
        <p className="text-gray-500 mb-4 font-bold md:text-2xl">{description}</p>
        <p className="text-gray-100 mb-4 md:text-lg">{details}</p>
        <button className="mt-2 bg-cyan-500 text-white py-2 px-6 rounded-3xl  flex justify-center items-center" onClick={openPortfolio}>
          Access Porfolio
          <div className="w-[10px] h-[10px] bg-white rounded-full ml-4"></div>
        </button>
        {images.length > 0 && (
          <div className={`grid gap-4 mt-4 ${images.length === 1 ? 'md:grid-cols-1' : images.length === 2 ? 'md:grid-cols-2' : 'md:grid-cols-3'}`}>
            {images.map((image, index) => (
              <div key={index} className="relative w-full h-full overflow-hidden rounded-lg">
                <img
                  src={image}
                  alt={`Image ${index + 1}`}
                  className="w-full h-full object-cover"
                />
              </div>
            ))}
          </div>
        )}


        {show3DModel && <BoxDisplay />} {/* Show 3D model only if show3DModel is true */}

      </div>

    </div>
  );
};

const ProjectsSection = () => {
  const [expandedCard, setExpandedCard] = useState(null);

  const projects = [
    {
      title: "Optifinish",
      description: "Immersive 3D Industrial Packaging Design.",
      details: "Optifinish features a sleek, modern packaging design in yellow and white, tailored for industrial powder coating tools. The design emphasizes customer engagement and sustainability, minimizing waste while ensuring a striking visual appeal. Rotate the box around for an immersive experience.",
      logo: "/images/optifinish.png",
      tags: ['Packaging', '3D Design', 'Prototype', 'Sustainability'],
      show3DModel: true,
      cover: "/images/opticover.png",
      images: []
    },
    {
      title: "Food 4 the Fortuneless",
      description: "Purpose-Driven Non-Profit Brand Identity.",
      details: "Crafted a distinctive brand identity and logo for a non-profit focused on food distribution to the underserved. The design combines purpose and empathy, creating a memorable, impactful brand presence that resonates with the mission of giving back.",
      logo: "/images/f4f.png",
      tags: ['Logo', 'Branding', 'Non Profit', 'Social Impact'],
      show3DModel: false,
      cover: "/images/opticover.png",
      images: ["images/f4fm1.png", "/images/f4fm2.png"]

    },
    {
      title: "Architsu",
      description: "Strategic Logo and Brand Mockups.",
      details: "Developed a professional, modern logo for Archetsu, a business consultancy firm, embodying its strategic expertise and forward-thinking approach. The project included sleek app icon and website mockups, aligning the brand’s digital presence with its consulting mission and enhancing user engagement across platforms.",
      logo: "/images/archetsu.png",
      tags: ['Logo', 'Branding', 'Mockup', 'UI/UX'],
      show3DModel: false,
      cover: "/images/opticover.png",
      images: ["/images/archetsum1.png", "/images/archetsum2.png"]

    },
    {
      title: "Audacity",
      description: "Cultural Learning Platform Brand Design.",
      details: "Designed a cohesive brand identity for Audacity, an online Kathak learning platform, supported by LMS and OBS integration. Our work included a culturally inspired logo, UI/UX designs, and a calming color theme that reflects the elegance of Kathak, ensuring an engaging and seamless learning experience.",
      logo: "/images/audacity.png",
      tags: ['UI/UX', 'Branding', 'Prototype', 'EdTech'],
      show3DModel: false,
      cover: "/images/opticover.png",
      images: ["/images/audacitym1.png", "/images/audacitym2.png"]


    },
    {
      title: "Apex Learning",
      description: "Comprehensive EdTech Brand Design Suite.",
      details: "Crafted a full branding package for Apex Learning, an edtech platform for SAT/ACT prep and college consulting. Our work encompassed logo and UI/UX designs, color templates, and engaging flyers and social media posts to amplify reach and impact among high school students.",
      logo: "/images/apexlogo.png",
      tags: ['Development', 'UI/UX', 'Branding', 'EdTech'],
      show3DModel: false,
      cover: "/images/opticover.png",
      images: ["/images/apexm1.png", "/images/apexm2.jpeg"]

    },
    {
      title: "Pind",
      description: "Urban Luxury Streetwear Brand Development.",
      details: "Developed the brand identity for Pind, an urban luxury streetwear line, overseeing social media, UI/UX, and operational strategy. Currently designing their app and website to create a cohesive, trendsetting digital presence that resonates with style-driven audiences.",
      logo: "/images/pind.png",
      tags: ['Fashion', 'Operations', 'Branding', 'Development'],
      show3DModel: false,
      cover: "/images/opticover.png",
      images: ["/images/pindm1.png", "/images/pindm2.jpeg", "/images/pindm3.jpeg"]

    }
  ];

  return (
    <div id="projects" className="relative">
      <div className="overflow-hidden whitespace-nowrap mb-12">
        <h1 className="md:text-8xl text-7xl text-center text-white flex items-center justify-center md:space-x-16 space-x-2 font-['Vidaloka']" style={{ animation: 'slide 40s linear infinite' }}>
          Featured Projects
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">Featured Projects</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">Featured Projects</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">Featured Projects</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">Featured Projects</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">Featured Projects</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">Featured Projects</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">Featured Projects</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">Featured Projects</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">Featured Projects</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">Featured Projects</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">Featured Projects</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">Featured Projects</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">Featured Projects</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">Featured Projects</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">Featured Projects</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">Featured Projects</span>
        </h1>
        <div className="w-screen h-[0.1vh] bg-gray-500 mt-8"></div>
      </div>

      <div className={`grid md:grid-cols-2 gap-4 px-0 md:px-8 md:gap-8 ${expandedCard ? 'blur-lg' : ''}`}>
        {projects.map((project, index) => (
          <ProjectCard
            key={index}
            {...project}
            onExpand={() => setExpandedCard(project)}
          />
        ))}
      </div>

      {expandedCard && (
        <ExpandedProjectCard
          {...expandedCard}
          onClose={() => setExpandedCard(null)}
        />
      )}

      <style jsx global>{`
        @keyframes slide {
          0% {
            transform: translateX(100%);
          }
          100% {
            transform: translateX(-100%);
          }
        }
      `}</style>
    </div>
  );
};

export default ProjectsSection;

import React, { useState, useEffect } from 'react';

const ClientReviews = () => {
  // Array of client reviews
  const reviews = [
    {
      name: "Harish Sharma",
      company: "Value Adding Coating Solutions Pvt. Ltd.",
      review: "We had the pleasure of collaborating with Nexuz for the branding of our latest product line, Optifinish, and the experience exceeded all expectations. Nexuz demonstrated a profound understanding of our objectives and market dynamics, resulting in a brand identity that truly captured the essence of Optifinish its premium quality and superior performance.",
    },
    {
      name: "Anonymous",
      company: "Data hidden due to NDA reasons",
      review: "Working with Nexuzforge was a game-changer for our business. They performed a comprehensive audit of our user experience design and helped us uncover critical pain points we hadn’t even considered. The redesign that followed was thoughtful, user-centric, and seamlessly improved our customers’ journey on our platform. Thanks to Nexuzforge.",
    },
    {
      name: "Anonymous",
      company: "Data hidden due to NDA reasons",
      review: "Nexuzforge completely transformed our brand identity. By focusing on detailed target audience personas, they crafted a fresh, authentic look that resonated with our customers like never before. We’ve seen increased customer recognition and stronger brand loyalty since the change. Nexuzforge’s strategic approach and design expertise are truly remarkable!",
    },
  ];

  // State to track the current review
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  // Function to switch to the next review
  const nextReview = () => {
    setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % reviews.length);
  };

  // Function to switch to the previous review
  const prevReview = () => {
    setCurrentReviewIndex((prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length);
  };

  const currentReview = reviews[currentReviewIndex];

  // Animated title styles
  const titleAnimationStyles = {
    animation: 'slide 40s linear infinite',
  };

  return (
    <div
      className="text-white bg-cover bg-center bg-no-repeat w-screen h-auto mb-12 mt-12"
       // Replace with your image path
      id="reviews"
    >
      {/* Animated Title */}
      <div className="overflow-hidden whitespace-nowrap">
      <h2 className="md:text-8xl text-7xl text-center text-white flex items-center justify-center md:space-x-16 space-x-2 font-['Vidaloka']" style={titleAnimationStyles}>

          Client Reviews
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">Client Reviews</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">Client Reviews</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">Client Reviews</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">Client Reviews</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">Client Reviews</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">Client Reviews</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">Client Reviews</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">Client Reviews</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">Client Reviews</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">Client Reviews</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">Client Reviews</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-white">Client Reviews</span>
          <span className="inline-block w-3 h-3 bg-cyan-500 rounded-full mx-10 flex-shrink-0"></span>
          <span className="text-gray-500">Client Reviews</span>
        </h2>
        <div className="w-screen h-[0.1vh] bg-gray-500 mt-8"></div>
      </div>

      <div className="flex justify-center md:mt-10 mt-3">
        <div
          className="py-5 rounded-lg text-center shadow-lg relative flex flex-col justify-center items-center bg-cover bg-center bg-no-repeat md:h-[70vh] md:w-[70vw] m-4"
      style={{ backgroundImage: `url('/images/clientrevcard.png')`}} 
 // Set fixed width and height
        >

                    <h3 className="md:text-3xl text-2xl font-semibold text-cyan-400">{currentReview.name}</h3>
                    <p className="md:text-lg text-gray-400">{currentReview.company}</p>
          {/* Client Review Text */}
          <p className="md:mb-6 md:text-lg font-light px-4 mt-10 text-sm">"{currentReview.review}"</p>
          {/* Client Name and Company */}

        </div>
      </div>

      {/* Navigation Buttons */}
      <div className="flex justify-center space-x-4 md:mt-8">
        <button
          onClick={prevReview}
          className="bg-cyan-400 text-white w-12 h-12 rounded-full flex items-center justify-center hover:bg-cyan-500"
        >
          ‹
        </button>
        <button
          onClick={nextReview}
          className="bg-cyan-400 text-white w-12 h-12 rounded-full flex items-center justify-center hover:bg-cyan-500"
        >
          ›
        </button>
      </div>

      {/* Background Animation */}
      <style jsx>{`
        .shadow-lg {
          box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25);
        }
      `}</style>
    </div>
  );
};

export default ClientReviews;
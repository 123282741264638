import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation to access the state for scrollTo
import HeroSection from './HeroSection';
import AboutSection from './AboutSection';
import TeamSection from './TeamSection';
import ProjectsSection from './ProjectsSection';
import ClientReviews from './ClientReviews';
import Footer from './Footer';
import ThankYouPopup from './ThankYouPopUp';
import { Link } from 'react-router-dom';


const LandingPage = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollTo) {
      const section = document.getElementById(location.state.scrollTo);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // Always scroll to the top of the page when no scrollTo is provided or on refresh
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [location.state]);




  return (
    <div>
      <div className="fixed bottom-5 right-5 z-[120]">
        <Link to="/projects">
          <div className="relative flex justify-center items-center w-20 h-20">
            <img
              src="/images/contact.png"
              alt="Your image"
              className="absolute w-12 h-12 rounded-full z-10"
            />
            <img
              src="/images/contactusani.gif"
              alt="GIF around the button"
              className="absolute w-20 h-20 rounded-full z-0"
            />
          </div>
        </Link>

      </div>
      <div style={{ backgroundColor: 'black' }}>
        <section id="hero">
          <HeroSection />
        </section>
      </div>

      <div
      >
        {/* Assign unique IDs to each section to allow smooth scrolling */}
        <section id="what-we-do">
          <AboutSection />
        </section>
        <section id="team">
          <TeamSection />
        </section>
        <section id="projects">
          <ProjectsSection />
        </section>
        <section id="reviews">
          <ClientReviews />
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;

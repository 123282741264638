import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './/components/LandingPage';  // Your default landing page
import StartProject from './/components/StartProject';  // The project section
import Privacy from './/components/Privacy';
import { useEffect } from 'react';


function App() {
  useEffect(() => {
    // Disable automatic scroll restoration in the browser
    window.history.scrollRestoration = 'manual';
  }, []);

  return (
    <Router>
      <Routes>
        {/* Route for the landing page */}
        <Route path="/" element={<LandingPage />} />
        {/* Route for the ProjectSection page */}
        <Route path="/projects" element={<StartProject />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </Router>
  );
}

export default App;

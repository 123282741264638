// ThankYouPopup.jsx
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Ensure react-router-dom is installed
import { Link } from 'react-router-dom';


const ThankYouPopup = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      // Redirect to home page after 5 seconds
      navigate('/');
      // Alternatively, if not using react-router-dom, use:
      // window.location.href = '/';
    }, 3000);

    // Cleanup the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-[#2a2a2a] rounded-xl p-4 max-w-md w-full text-cente font-['Vidaloka']">
      <Link to="/">
      <img src="/images/tycross.png" className="h-4" alt="Home" />
    </Link>      <img className="mx-auto"src="/images/submission.gif"></img>
        <h2 className="text-3xl mb-4  text-white text-center">Inquiry Sent Successfuly!</h2>
        <p className="text-2xl text-cyan-400 text-center">
          Our Team will get in touch with you soon over email...
        </p>
      </div>
    </div>
  );
};

export default ThankYouPopup;
